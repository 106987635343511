import { graphql } from 'gatsby';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import { getPageLink } from '../constants';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../util/utils';
import './NotificationsPage.scss';
import { PageContentType } from '../context/PageContentType';

/**
 * Notifications Page
 *
 * @param props
 * @constructor
 */
const NotificationsPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const state = useContext(GlobalStateContext);

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.notifications'),
      path: getPageLink(props.pageContext.lang, 'NOTIFICATIONS'),
    },
  ];

  /**
   * Get Message
   *
   * @param msg
   * @param product
   */
  const getMessage = (msg, productName) => {
    return t('notificationsPage.' + msg).replace('###product###', productName);
  };

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'NOTIFICATIONS')}
      pageTitle={t('notificationsPage.title')}
    >
      <div className="ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <h1>{t('notificationsPage.title')}</h1>

          <p>{t('notificationsPage.desc')}</p>

          {state.userProfile.notifications.length ? (
            <div className="ontario-table-container ontario-small-12 ontario-medium-12 ontario-large-12">
              <div className="ontario-table-scroll--wrapper">
                <div className="ontario-table-scroll--div"></div>
              </div>
              <div className="mdmp__notifications ontario-table-div">
                <table tabIndex={0}>
                  <caption className="sr-only">
                    {t('notificationsPage.title')}
                  </caption>
                  <thead>
                    <tr>
                      <th scope="col" className={'mdmp__first-field'}>
                        {t('notificationsPage.type')}
                      </th>
                      <th scope="col">{t('notificationsPage.details')}</th>
                      <th
                        scope="col"
                        className="mdmp__field-date ontario-table-cell--numeric"
                      >
                        {t('notificationsPage.date')}
                      </th>
                      <th
                        scope="col"
                        className="mdmp__field-time ontario-table-cell--numeric"
                      >
                        {t('notificationsPage.time')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.userProfile.notifications.map((v, k) => {
                      return (
                        <tr key={k}>
                          <th
                            scope="row"
                            className={'mdmp__first-field mdmp__field-purchase'}
                          >
                            {t('notificationsPage.purchase')}
                          </th>
                          <td>{getMessage(v.message, v.name)}</td>
                          <td>{getDateFromTimestamp(v.createdOn)}</td>
                          <td>{getTimeFromTimestamp(v.createdOn)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <p>{t('notificationsPage.noNotifications')}</p>
          )}
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default NotificationsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
